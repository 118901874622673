* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  color: #212529;
}

p {
  margin-bottom: 1.5rem;
}

h1 {
  font-size: 22px;
}

ol {
  list-style-position: outside;
  padding-left: 18px;
  margin: 1.5rem 0 2rem 0;
}

ol ol {
  margin: 5px 0 10px;
}

.App {
  background: #f5f5f5;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  padding-top: calc(3rem + 44px);
}

.header, .footer {
  width: 100%;
  background: #ffffff;
}

.header {
  border-bottom: 1px solid #dee2e6;
  left: 0;
  position: fixed;
  padding: 1.5rem 0;
  right: 0;
  top: 0;
  z-index: 10;
}

.header-logo {
  width: auto;
  height: 40px;

  @media (max-width: 768px) {
    max-height: 150px;
  }
}

.footer {
  border-top: 1px solid #dee2e6;
  margin-top: 3rem;
}

.footer-logo {
  max-width: 200px;
  display: flex;
  margin: 50px 50px 50px auto;


  @media (max-width: 768px) {
    max-width: 150px;
  }
}

.app-page {
  background: #ffffff;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  padding-bottom: 3rem;
}

.app-page-title {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 40px;

}

.app-page-content {
  background: #ffffff;
  margin-top: 0 !important
}

.form-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
}

.form-group {
  background: #ffffff;
  padding: 1.5rem !important;
  border: 1px solid #dee2e6;
  margin-top: 40px !important;
}

.form-group > MuiGrid-item {

}

.form-section-description {
  margin-bottom: 10px;
}

.form-section-label {
  font-size: 20px;
  font-weight: 700;
  color: #055696;
  text-transform: capitalize;
}

.form-section-label-md {
  font-size: 16px;
  font-weight: bold;
}

.form-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-input-label {
  font-size: 16px;
}

.form-select {
  margin: 500px;
}

.form-header {
  font-size: 24px;
  font-weight: bold;
}

.file-error {
  color: red;
  font-size: 14px;
  font-style: italic;
}

.completed-text {
  font-size: 20px;
  font-weight: bold;
  margin: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.success-text {
  margin-top: 20px;
  color: #4caf50;
}

.consent-link {
  color: #3c21c7;
}
